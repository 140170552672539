document.addEventListener('turbolinks:load', function() {
  $(".data_products").on('change', 'select.package-select', (e) => {
    const $pkgSelect = $(e.target);
    const pkgId = e.target.value;
    const $fieldChoiceSelect = $pkgSelect.parent('td').next().find('select');
    let newOptions = '<option value="">Choose a field</option>';
    if (pkgId === "") {
      newOptions += document.allPackageFilterChoices.map(e => `<option value="${e}">${e}</option>`).join();
    } else {
      if (document.packageSpecificFilterChoices[pkgId]) {
        newOptions += document.packageSpecificFilterChoices[pkgId].map(e => `<option value="${e}">${e}</option>`).join();
      }
    }
    $fieldChoiceSelect.empty();
    $fieldChoiceSelect.append(newOptions);
  });
});
