// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import 'jquery'
import 'popper.js'
import "bootstrap"
import "../stylesheets/admin"

import "../admin/data_products_form.js"
import "../admin/user_form.js"

document.addEventListener('turbolinks:load', function() {
  $('body').on('change', '.custom-file-input',  (e) => {
    const label = $(e.target).next('label');
    label.text( e.target.value.replace(/^.*[\\\/]/, ''));
  });
}, false);
