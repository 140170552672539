document.addEventListener('turbolinks:load', function() {
  $(".users form").on('change', 'select#user_vendor_role', (e) => {
    const $roleSelect = $(e.target);
    const roleName = e.target.value;
    console.log(e)
    console.log(roleName)
    if (roleName == "vendor_data_owner") {
      $("#data-product-selection").addClass("d-block");
      $("#data-product-selection").removeClass("d-none");
    } else {
      $("#data-product-selection").removeClass("d-block");
      $("#data-product-selection").addClass("d-none");
    }
  });
});
